import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { layout, position, space } from 'styled-system';

import { filteredProps } from '@shared/style-helpers';

const ImageWrapper = styled(GatsbyImage).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // image and imageStyle need to be forwarded to GatsbyImage
    ['image', 'imgStyle'].includes(prop) ||
    (!filteredProps.includes(prop) && defaultValidatorFn(prop)),
})`
  ${space}
  ${layout}
  ${position}

  > div {
    height: 100%;
  }

  // Since objectFit property is passed to GatsbyImage component, we need to overriding this property for image placeholder as well to meet original image size.
  > [data-placeholder-image] {
    object-fit: contain !important;
  }
`;

const LazyImage = ({ image, alt, imageProps, loading = 'lazy' }) => (
  <ImageWrapper
    alt={alt}
    image={image}
    loading={loading}
    imgStyle={{ objectFit: 'contain' }}
    {...imageProps}
  />
);

export default LazyImage;
